import axios from './../../request/axios.js'
import api from './../../request/api.js'

const homeService = {
  // 机构
  OrganizationBaseInformation: function (params) {
    return axios.post(api.management.Organization.baseInformation, params)
  },
  OrganizationVisionDistribution: function (params) {
    return axios.post(api.management.Organization.visionDistribution, params)
  },
  OrganizationWarningNumber: function (params) {
    return axios.post(api.management.Organization.warningNumber, params)
  },
  OrganizationNumberLine: function (params) {
    return axios.post(api.management.Organization.numberLine, params)
  },
  OrganizationMaleFemale: function (params) {
    return axios.post(api.management.Organization.maleFemale, params)
  },
  OrganizationMyopiaAgeGroup: function (params) {
    return axios.post(api.management.Organization.myopiaAgeGroup, params)
  },
  // 政府
  governmentBaseInformation: function (params) {
    return axios.post(api.management.government.baseInformation, params)
  },
  governmentEarlyWarning: function (params) {
    return axios.post(api.management.government.earlyWarning, params)
  },
  governmentMyopiaRate: function (params) {
    return axios.post(api.management.government.myopiaRate, params)
  },
  governmentNewTrend: function (params) {
    return axios.post(api.management.government.newTrend, params)
  },
  governmentNewStatistics: function (params) {
    return axios.post(api.management.government.newStatistics, params)
  },
  governmentTrendsInMyopia: function (params) {
    return axios.post(api.management.government.trendsInMyopia, params)
  },
  governmentDegreeMyopia: function (params) {
    return axios.post(api.management.government.degreeMyopia, params)
  },
  governmentMaleFemaleRatio: function (params) {
    return axios.post(api.management.government.maleFemaleRatio, params)
  },
  // 学校
  schoolGetSchoolStatistics: function (params) {
    return axios.post(api.management.school.getSchoolStatistics, params)
  },
  schoolGetSchoolTop: function (params) {
    return axios.post(api.management.school.getSchoolTop, params)
  },
  schoolGetSchoolNumsLine: function (params) {
    return axios.post(api.management.school.getSchoolNumsLine, params)
  },
  schoolGetNewGrade: function (params) {
    return axios.post(api.management.school.getNewGrade, params)
  },
  schoolLock: function (params) {
    return axios.post(api.management.school.schoolLock, params)
  },
  // 班级
  classAndGradeGetClassBaseInformation: function (params) {
    return axios.post(api.management.classAndGrade.getClassBaseInformation, params)
  },
  classAndGradeGetClassAbnormalAndNormalNums: function (params) {
    return axios.post(api.management.classAndGrade.getClassAbnormalAndNormalNums, params)
  },
  classAndGradeGetClassEarlyWarning: function (params) {
    return axios.post(api.management.classAndGrade.getClassEarlyWarning, params)
  },
  classAndGradeGetClassMyopiaRateTopThree: function (params) {
    return axios.post(api.management.classAndGrade.getClassMyopiaRateTopThree, params)
  },
  classAndGradeGetBoyAndGirlNums: function (params) {
    return axios.post(api.management.classAndGrade.getBoyAndGirlNums, params)
  },
  classAndGradeGetClassMyopiaNums: function (params) {
    return axios.post(api.management.classAndGrade.getClassMyopiaNums, params)
  },
  classAndGradeGetFocusStudent: function (params) {
    return axios.post(api.management.classAndGrade.getFocusStudent, params)
  }
}
export default homeService
